import { Box, Button, Image, Text } from '@chakra-ui/react';
import {
  getMobileOperatingSystem,
  OperatingSystemType
} from '@/helpers/system';
import { useEffect, useState } from 'react';
import { CloseIcon } from '@/theme/Icons';
import { useExtraProps } from '@/pages/_app';
import { getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

const excludedPathnames = ['/sports-pmu/[slug]'];

const SmartAppBanner = () => {
  const { t } = useTranslation();
  const isSmartBannerAlreadyClosed = getCookie('smart-banner-closed');
  const { license } = useExtraProps();
  const [operatingSystem, setOperatingSystem] =
    useState<OperatingSystemType | null>(null);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  useEffect(() => {
    setOperatingSystem(getMobileOperatingSystem());
  }, []);
  useEffect(() => {
    setIsOpened(!isSmartBannerAlreadyClosed);
  }, [isSmartBannerAlreadyClosed]);
  const { pathname } = useRouter();
  if (excludedPathnames.includes(pathname)) return null;
  if (license !== 'F') return null;
  if (isSmartBannerAlreadyClosed) return null;
  if (!isOpened) return null;
  if (operatingSystem === 'unknown' || operatingSystem === null) return null;
  return (
    <Box
      position={'fixed'}
      bottom={0}
      left={0}
      zIndex={999999999}
      width={'100%'}
      padding={'0.5rem'}
    >
      <Box
        bg={'backgroundPrimary.900'}
        borderWidth={'1px'}
        borderStyle={'solid'}
        borderColor={'buttonPrimary'}
        borderRadius={'1.25rem'}
        display={'flex'}
        maxHeight={'182px'}
      >
        <Image src={'/goldenPalace/smart-app-banner.webp'} />
        <Box
          padding={'0.75rem'}
          flexGrow={1}
          display={'flex'}
          flexDirection={'column'}
        >
          <CloseIcon
            marginLeft={'auto'}
            cursor={'pointer'}
            onClick={() => {
              setIsOpened(false);
              setCookie('smart-banner-closed', true, {
                maxAge: 60 * 60 * 24 * 14
              });
            }}
          />
          <Box marginTop={'0.5rem'}>
            <Text fontWeight={500} fontSize={'1.125rem'} lineHeight={'1.5rem'}>
              {t('common:smartBannerTitle')}
            </Text>
            <Text fontSize={'0.875rem'} lineHeight={'1.25rem'}>
              {t('common:smartBannerSubtitle')}
            </Text>
          </Box>
          <Button
            onClick={() => {
              window.location.href = window.location.origin + '/mobile_app';
            }}
            background={'buttonPrimary'}
            width={'100%'}
            marginTop={'auto'}
          >
            {t('common:smartBannerCTA')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default SmartAppBanner;
