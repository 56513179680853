import React, { PropsWithChildren, ReactElement } from 'react';
import {
  FieldError,
  FieldValues,
  RegisterOptions,
  useFormContext
} from 'react-hook-form';
import { FormControl, Select, SelectProps } from '@chakra-ui/react';
import InputError from './InputError';
import { InputLabel } from '..';
import { get } from 'lodash';

type InputSelectProps = PropsWithChildren &
  SelectProps & {
    name: string;
    placeholder?: string;
    label?: string;
    validation?: RegisterOptions<FieldValues>;
  };

export const InputSelect = ({
  placeholder,
  children,
  label,
  width,
  validation,
  ...props
}: InputSelectProps) => {
  const {
    register,
    formState: { errors },
    setFocus,
    watch,
    getValues
  } = useFormContext();

  const fieldError = get(errors, props.name) as unknown as FieldError | string;

  const value = getValues(props.name);

  return (
    <FormControl
      position="relative"
      isInvalid={!!fieldError}
      width={width}
      isRequired={!!validation?.required || props.isRequired}
    >
      <Select
        height="3.8rem"
        fontSize="1.2rem"
        fontWeight="bold"
        {...register(props.name, { ...validation })}
        {...props}
      >
        {children}
      </Select>
      <InputLabel
        label={label}
        // subLabel={subLabel}
        name={props.name}
        color="whiteAlpha.600"
        position="absolute"
        zIndex="10"
        left="1.5rem"
        transition="all .1s ease-in-out"
        fontSize="1.2rem"
        top="50%"
        transform="translateY(-50%)"
        className="float-label"
        onClick={() => {
          setFocus(props.name);
        }}
        style={
          watch(props.name as string) ||
          !!(fieldError as FieldError)?.message ||
          !!value
            ? { top: '0.8rem', fontSize: '0.7rem' }
            : {}
        }
      />
      <InputError error={fieldError} />
    </FormControl>
  );
};

export default InputSelect;
