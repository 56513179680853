import { useAppSettings } from '@/context/AppSettings';
import { useAuth } from '@/context/Auth';
import { APIUserType } from '@/context/Auth.types';
import { useCurrentHost } from '@/hooks/useCurrentHost';
import { useExtraProps } from '@/pages/_app';
import { getFullUrlByDomain, getFullUrlByLicense } from '@/utils/multiDomains';
import {
  Button,
  Divider,
  Grid,
  GridItem,
  Link,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  UseDisclosureProps,
  UseModalProps,
  useToken
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { Form, Input } from '../';
import useLogin from '../../hooks/useLogin';
import { LogoTestAccount } from '@/components/Logo';
import { strapiImageUrl } from '@/helpers/url';
import { useLoginModal } from '@/context/LoginModal';

type LoginModalProps = UseModalProps &
  UseDisclosureProps & {
    callback?: (user: APIUserType) => void;
    onCloseModal?: () => void;
  };

const LoginModal = ({ callback, onCloseModal, ...props }: LoginModalProps) => {
  const { lang, t } = useTranslation();
  const appSettings = useAppSettings();
  const currentHost = useCurrentHost();
  const { isLogged } = useAuth();
  const router = useRouter();
  const { license } = useExtraProps();
  const { backgroundUrl } = useLoginModal();
  const { pathname } = useRouter();
  const background = backgroundUrl
    ? `url('${strapiImageUrl(backgroundUrl)}') no-repeat center center/cover`
    : appSettings.customStyle.loginSectionLeft[license];

  const ModalView = () => {
    const { loading, onSubmit, form } = useLogin({
      callback,
      onEnd: props.onClose,
      license
    });
    return (
      <Grid
        templateAreas={[
          `"header""logo""form"`,
          `"header""logo""form"`,
          `"logo header""logo form"`
        ]}
        flexDirection={'row'}
        overflow={'scroll'}
        height={['100vh', '100vh', 'auto']}
      >
        <GridItem
          area={'logo'}
          background={background}
          className="login-left-section"
          position="relative"
          width={['100%', '100%', '24.625rem']}
          minHeight={['15.25rem', '15.25rem', '100%']}
          borderRadius={['0', '0', '2rem 0 0 2rem']}
        ></GridItem>
        <GridItem
          area={'header'}
          width={['100%']}
          display={'flex'}
          borderBottom={['1px solid', '1px solid', 'none']}
          borderColor={'figma.neutral.20'}
        >
          <LogoTestAccount
            iconProps={{ height: ['1.5rem', '2.5rem', '2.5rem'] }}
            height={'auto'}
            margin={['1rem auto', '1rem auto', '3.75rem auto 0 auto']}
          />
        </GridItem>
        <GridItem
          area={'form'}
          flexDirection={'column'}
          flexGrow={1}
          padding={[
            '0 1rem 2.75rem 1rem',
            '0 1rem 2.75rem 1rem',
            '0 7.7rem 3.75rem 7.7rem'
          ]}
        >
          <Text
            marginTop={['2.5rem']}
            marginBottom={['2rem']}
            color={'white'}
            fontSize={['1.5rem']}
            fontWeight={500}
            lineHeight={['1.875rem']}
            textAlign={['left', 'left', 'center']}
          >
            {t('common:loginTitle')}
          </Text>
          <Form
            stackProps={{
              spacing: '1rem'
            }}
            onSubmit={onSubmit}
            {...form}
          >
            <Input
              id="loginModalUsername"
              type="text"
              padding="1.5rem"
              color={'white'}
              label={t('common:userNameOrEmail')}
              fontSize={['0.875rem']}
              labelProps={{
                fontSize: ['0.875rem']
              }}
              name="username"
              minLength={3}
              validation={{
                required: 'Missing username'
              }}
            />
            <Input
              id="loginModalPassword"
              type="password"
              forceTextType={false}
              color={'white'}
              padding="1.5rem"
              marginTop="1rem"
              label={t('common:enterYourPassword')}
              fontSize={['0.875rem']}
              labelProps={{
                fontSize: ['0.875rem']
              }}
              name="password"
              validation={{
                required: 'Missing password'
              }}
            />
            <Button
              as="a"
              id="loginModalForgot"
              color="white"
              alignSelf="flex-start"
              variant="link"
              marginBottom="2rem !important"
              marginTop={'0 !important'}
              padding={'0.5rem 1rem'}
              fontSize="0.75rem"
              fontWeight={500}
              lineHeight={'1rem'}
              href={`${getFullUrlByLicense(license, lang)}/forgot-password`}
              className="forgotLink"
            >
              {t('common:forgotPassword')}
            </Button>
            <Button
              id="loginModalSubmit"
              isLoading={form.formState.isSubmitting || loading}
              backgroundColor="buttonPrimary"
              size="md"
              type="submit"
              paddingX="3rem"
              paddingY="1.8rem"
              fontSize={['0.875rem']}
              fontWeight="bold"
              marginTop="0 !important"
              marginBottom="0 !important"
              width="100%"
            >
              {t('common:login')}
            </Button>
            <Divider mt={'2rem !important'} mb={'2rem !important'} />
            <Text
              fontSize={['0.875rem']}
              lineHeight={['1.25rem']}
              marginTop="0 !important"
              marginBottom="1rem !important"
              color={'figma.neutral.500'}
            >
              {t('common:notMemberYet')}
            </Text>
            <Button
              as="a"
              id="loginModalRegister"
              marginTop={'0 !important'}
              fontSize={['0.875rem']}
              variant="outline"
              width="100%"
              href={getFullUrlByLicense(license, lang) + '/register'}
            >
              {t('common:register')}
            </Button>
            <Text
              fontSize={['0.75rem']}
              lineHeight={['1rem']}
              color={'white'}
              textAlign={'center'}
              mt={['2rem !important']}
            >
              {t('common:addictiveGameInfo')}
              <br />
              <Link
                href={`https://${t('common:addictiveGameUrl')}`}
                target={'_blank'}
                textDecoration={'underline'}
              >
                {t('common:addictiveGameUrl')}
              </Link>
            </Text>
          </Form>
        </GridItem>
      </Grid>
    );
  };

  const [buttonPrimary] = useToken('colors', ['buttonPrimary']);

  return isLogged ? null : (
    <>
      <Modal
        isCentered
        {...props}
        isOpen={props.isOpen || pathname === '/[segment]/login'}
      >
        <ModalOverlay className="modal-login" background={'unset !important'} />
        <ModalContent
          maxWidth={['100%', '100%', '64rem']}
          width={['100%', '100%', 'auto']}
          height={['auto']}
          boxShadow={['none', 'none', '0 20px 120px 0']}
          color={`${buttonPrimary}66`}
          borderColor={'figma.neutral.20'}
          marginBottom={0}
          marginTop={0}
          borderRadius={['0', '0', '2rem']}
        >
          <ModalCloseButton
            top="1rem"
            right="1rem"
            color={'white'}
            onClick={() => {
              const url = getFullUrlByDomain(lang, currentHost);
              const isLoginPage = router.pathname.endsWith('login');
              if (isLoginPage && url) router.push(url);
              props.onClose();
              onCloseModal?.();
            }}
          />
          <ModalView />
        </ModalContent>
      </Modal>
    </>
  );
};
LoginModal.getInitialProps = async () => {
  return {};
};
export default LoginModal;
